import Link from 'next/link';
import styles from './Footer.module.scss';
import ShippingInfo from './shipping-info/ShippingInfo';

export default function Footer({ footerDocs }) {
  const d = new Date();
  const thisYear = d.getFullYear();

  return (
    <div className={styles.container}>
      <ShippingInfo />

      <hr />
      <div className={styles.content}>
        <div className={styles.brand}>
          <img src="/logo/logo.svg" alt="Myrevolution logo" />
          <img src="/logo/klarna.png" alt="Klarna payment" />
        </div>

        {footerDocs && footerDocs.map((docBundle, i) => (
          <div className={styles.block} key={docBundle.id}>
            <div className={styles.title}>{docBundle.name.substring(1)}</div>
            <ul>
              {docBundle.children.map((doc) => (
                <li key={doc.id}>
                  <Link prefetch={false} href="/page" as={doc.path}>
                    {doc.name}
                  </Link>
                </li>
              ))}
              {i === 0 && (
                <li>
                  <a href="/min-side">Min Side</a>
                </li>
              )}
            </ul>
          </div>
        ))}
      </div>

      <hr />

      <div className={styles.bottomPart}>
        <div>
          <div className={styles.cookies}>
            <img
              src="/icons/icon-cookie.svg"
              alt="Myrevolution Cookie Disclaimer"
            />
            <span>
              Vår nettside bruker cookies slik at du kan plassere ordre og vi
              kan utføre bedre service for deg.
            </span>
          </div>
          <br />
          <div>
            <a href="/"> Les mer om vår bruk av cookies her</a>
          </div>
        </div>

        <br />
        <div className={styles.ecommerceBy}>
          <a href="https://crystallize.com">Ecommerce by Crystallize</a>
        </div>

        <div className={styles.copyright}>
          © Copyright
          {thisYear}
          {' '}
          MyRevolution.no. All rights reserved.
        </div>
      </div>
    </div>
  );
}
