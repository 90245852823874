import React from 'react';

// simple hook for checking if component is mounted or not
// -> used for skipping effects on initial render (with dep array)
export const useIsMounted = () => {
  const isMountedRef = React.useRef(false);

  React.useEffect(() => {
    isMountedRef.current = true;
  }, []);

  return isMountedRef.current;
};

export default useIsMounted;
