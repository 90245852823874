export const addressFragment = `
fragment address on Address {
  country
  streetNumber
  city
  street
  postalCode
  street2
  state
}
`;

export const customerFragment = `
fragment customer on Customer {
  firstName
  middleName
  lastName
  emailAddress
  phoneNumber
  deliveryAddress {
    ...address
  }
  billingAddress {
    ...address
  }
}
${addressFragment}
`;

export const fragments = `
fragment subscriptionItem on ProductSubscription {
  id
  sku
  shippingSku
  quantity
  imageUrl
  nextOrderAt
  initialPeriod {
    unit
    value
    price
  }
  recurringPeriod{
    unit
    value
    price
  }
  customer{
    ...customer
  }
}
${customerFragment}
`;

export const cartItemFragment = `
fragment cartItem on CartItem {
  name
  quantity
  price {
    gross
    net
  }
  imageUrl
  sku
  productId
  productVariantId
}
`;
