import ReactDOM from 'react-dom';
import React from 'react';
import useEscapeModal from '../../../hooks/useEscapeModal';
import styles from './styles.module.scss';

function Modal({ handleClose, render, body }) {
  const modalRef = React.useRef(null);
  const modalEscaped = useEscapeModal({ ref: modalRef });

  React.useEffect(() => {
    if (modalEscaped === true) {
      handleClose();
    }
  }, [modalEscaped]);

  const modalPlaceholderEl = document.getElementById('modal');

  function ModalEl() {
    return (
      <div className="modal-container" ref={modalRef}>
        <div className="modal-bounce">
          {
            render ? render() : (
              <div className={styles.container}>
                {body.title && <h1>{body.title}</h1>}
                <div>{body.content}</div>
              </div>
            )
          }
        </div>
      </div>
    );
  }

  return (
    ReactDOM.createPortal(<ModalEl />, modalPlaceholderEl)
  );
}

export default React.memo(Modal, () => true);
