import React from 'react';
import { Image } from '@crystallize/react-image';
// import Link from 'next/link';
import { useBasket } from '../../../basket';
import styles from './styles.module.scss';

export default function SuggestedProduct() {
  const [suggestedProducts, setSuggestedProducts] = React.useState([]);
  const basket = useBasket();

  function setupSuggestedProducts() {
    const sugProdList = [];

    // find and extract items with suggested products
    /* eslint-disable-next-line */
    for (let basketItem of basket.cart) {
      if (basketItem.suggestedProducts) {
        sugProdList.push(...basketItem.suggestedProducts);
      }
    }

    const uniqueSugProdList = sugProdList.filter((item, i, array) => array.findIndex((e) => (e.id === item.id)) === i);
    setSuggestedProducts(uniqueSugProdList.slice(0, 2));
  }

  // set suggested product
  React.useEffect(() => {
    if (basket.status === 'ready') {
      setupSuggestedProducts();
    }
  }, [basket.cart, basket.status]);

  if (suggestedProducts.length === 0) {
    return null;
  }

  return (
    <div className={styles.suggestedProductContainer}>
      <div>Du vil kanskje også like: </div>

      {suggestedProducts.map((prod) => (
        <div className={styles.suggestedProductWrapper}>
          <Image {...prod.variants?.[0]?.images[0]} sizes="100px" />
          <div className={styles.suggestedProductInfo}>
            <p>{prod.name}</p>
            <a href={prod.path}>Kjøp nå</a>
          </div>
        </div>
      ))}

    </div>
  );
}
