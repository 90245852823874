/* eslint-disable no-param-reassign */
import produce from 'immer';
// import { trackBasketAction } from '../../../gtm/tracking';
// import { BASKET_ITEM_ADD } from '../../../gtm/triggers';

export const initialState = {
  status: 'not-hydrated',
  /**
   * A simplistic basket which gets stored on client side
   * Each client cart item consists of these fields:
   *  - sku
   *  - path
   *  - priceVariantIdentifier
   *  - quantity
   */
  clientBasket: {
    cart: [],
    voucherCode: null,

    crystallizeOrderId: null,

    klarnaOrderId: null,
  },

  // The basket cart item to draw attention to
  attentionCart: {},
};

export default produce((draft, { action, ...rest }) => {
  /**
   * This flag helps keeping track of if the incoming
   * change is triggered by _this_ browser tab or a
   * different browser tab
   */
  draft.changeTriggeredByOtherTab = false;
  switch (action) {
    case 'hydrate': {
      if (draft.status === 'not-hydrated' || draft.status === 'server-basket-is-stale') {
        if (rest.cart) {
          draft.clientBasket = rest || initialState.clientBasket;

          if (!draft.clientBasket.cart) {
            draft.clientBasket.cart = initialState.clientBasket.cart;
          }
        }
        draft.status = 'server-basket-is-stale';
      }
      break;
    }

    case 'channel-update': {
      draft.clientBasket = rest.clientBasket;
      draft.changeTriggeredByOtherTab = true;
      draft.status = 'ready';
      break;
    }

    case 'set-crystallize-order-id': {
      draft.clientBasket.crystallizeOrderId = rest.crystallizeOrderId;
      break;
    }

    case 'set-klarna-order-id': {
      draft.clientBasket.klarnaOrderId = rest.klarnaOrderId;
      break;
    }

    case 'set-server-basket': {
      draft.serverBasket = rest.serverBasket;

      // update the price from the server -> client basket
      for (const clientBasketItem of draft.clientBasket.cart) {
        for (const serverBasketItem of draft.serverBasket.cart) {
          if (clientBasketItem.sku === serverBasketItem.sku) {
            clientBasketItem.price = serverBasketItem.price.gross;
          }
        }
      }
      draft.status = 'ready';
      break;
    }

    case 'server-update-failed': {
      draft.status = 'server-update-failed';
      break;
    }

    case 'retry-server-update': {
      draft.status = 'server-basket-is-stale';
      break;
    }

    case 'empty': {
      draft.clientBasket = initialState.clientBasket;
      // GTM
      // trackBasketAction(BASKET_ITEM_REMOVE, [rest.model])
      break;
    }

    case 'add-item':
    case 'remove-item':
    case 'increment-item':
    case 'decrement-item': {
      const {
        sku, path, priceVariantIdentifier = 'default', subscription = false, subscriptionPlan, isPackaged, price,
      } = rest;

      if (action === 'add-item') {
        if (typeof window !== 'undefined' && typeof window.snaptr !== 'undefined') {
          window.snaptr('track', 'ADD_CART', {
            price,
            currency: 'NOK',
          });
        }
      }

      if (!sku) {
        throw new Error(`Please provide "sku" and "path" for ${action}`);
      }

      let itemIndex;

      if (subscription) {
        itemIndex = draft.clientBasket.cart.findIndex(
          (i) => i.sku === sku && i.subscriptionPlan === subscriptionPlan,
        );
      } else { // PRODUCT
        itemIndex = draft.clientBasket.cart.findIndex(
          (i) => i.sku === sku && !!i.subscription === false,
        );
      }

      // item found in basket - increment/decrement
      if (itemIndex !== -1) {
        if (action === 'remove-item') {
          draft.clientBasket.cart.splice(itemIndex, 1);
        } else {
          const item = draft.clientBasket.cart[itemIndex];

          if (action === 'decrement-item') {
            item.quantity -= 1;
          } else {
            item.quantity += isPackaged ? rest.model.quantity : 1;
          }
        }
      // item not found in basket -> so its new item
      } else if (!['remove-item', 'decrement-item'].includes(action)) {
        // add to cart
        draft.clientBasket.cart.push({
          sku,
          path,
          priceVariantIdentifier,
          quantity: 1,
          ...rest,
        });

        draft.attentionCart = {
          time: Date.now(),
        };
      }

      draft.status = 'server-basket-is-stale';
      break;
    }

    case 'draw-attention': {
      draft.attentionCart = {
        time: Date.now(),
        // sku: rest.sku,
      };
      break;
    }

    case 'add-voucher': {
      draft.clientBasket.voucherCode = rest.voucherCode;
      draft.status = 'server-basket-is-stale';
      break;
    }

    case 'remove-voucher': {
      draft.clientBasket.voucherCode = initialState.clientBasket.voucherCode;
      draft.status = 'server-basket-is-stale';
      break;
    }

    default: {
      throw new Error(`Action ${action} not supported`);
    }
  }

  // A cart item is only valid if we have sku
  if (draft.clientBasket.cart.length > 0) {
    draft.clientBasket.cart = draft.clientBasket.cart.filter(
      ({ sku }) => sku,
    );
  }

  draft.totalQuantity = draft.clientBasket.cart.reduce(
    (acc, c) => acc + c.quantity,
    0,
  );
});
