import React from 'react';

export default function useIsScrollingDown() {
  let lastScrollTop = null;
  const [isScrollingDown, setIsScrollingDown] = React.useState(false);

  function handler() {
    if (window.pageYOffset > lastScrollTop) {
      setIsScrollingDown(true);
    } else {
      setIsScrollingDown(false);
    }

    lastScrollTop = window.pageYOffset;
  }

  React.useEffect(() => {
    // init the value
    lastScrollTop = window.pageYOffset;

    document.addEventListener('scroll', handler);

    return (() => {
      document.removeEventListener('scroll', handler);
    });
  }, []);

  return isScrollingDown;
}
