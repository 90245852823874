import styles from './ShippingInfo.module.scss';

export default function ShippingInfo() {
  return (
    <div className={styles.container}>
      <div className={styles.block}>
        <img src="/icons/icon-security.svg" alt="Trygg netthandel" />
        <h4>TRYGG NETTHANDEL</h4>
      </div>
      <div className={styles.block}>
        <img src="/icons/icon-box.svg" alt="Trygg netthandel" />
        <h4>
          FRI FRAKT
          {' '}
          OVER 800,-
        </h4>
      </div>
      <div className={styles.block}>
        <img src="/icons/kundeklubb.svg" alt="Trygg netthandel" />
        <h4>
          100%
          FORNØYDGARANTI
        </h4>
      </div>
      <div className={styles.block}>
        <img src="/icons/icon-truck.svg" alt="Lynrask levering" />
        <h4>LYNRASK LEVERING</h4>
      </div>
    </div>
  );
}
