import React from 'react';
import styles from './Basket.module.scss';
import { useBasket } from '../../../../templates/basket';
import { useIsMounted } from '../../../../../hooks/useIsMounted';
import Spinner from '../../../../atoms/spinner';

export default function Basket({ toggleBasket, basketMenuBtnRef }) {
  const basket = useBasket();
  const isMounted = useIsMounted();
  const [drawAttention, setDrawAttention] = React.useState(false);
  const [total, setTotal] = React.useState(null);

  // by using ref we're skipping effect for initial render
  React.useEffect(() => {
    if (isMounted) {
      setDrawAttention(true);
    }
  }, [basket?.attentionCart]);

  // calculate the total value of basket
  React.useEffect(() => {
    // if empty
    if (!basket.cart || basket.cart.length === 0) {
      setTotal(0);
      return;
    }

    // if server basket is still not populated - render client basket
    if (!basket.serverBasket?.totalPrice?.gross) {
      setTotal(basket.total);
      return;
    }

    // if server basket is ready
    if (basket.status === 'ready') {
      setTotal(Math.round(basket.serverBasket?.totalPrice?.gross));
    }
  }, [basket.serverBasket, basket.cart, basket.status]);

  function removeAnim() {
    setDrawAttention(false);
  }

  return (
    <button
      onKeyPress={toggleBasket}
      onClick={toggleBasket}
      tabIndex={0}
      type="button"
      className={`${styles.button} ${drawAttention && styles.getAttention}`}
      onAnimationEnd={removeAnim}
      ref={basketMenuBtnRef}
    >
      <span className={styles.span}>
        <div className={styles.infoDiv}>
          <div>
            <div className={styles.totalInBasket}>
              <p>
                <span>
                  {basket.status !== 'ready' && <span style={{ margin: '3px' }}><Spinner size={15} /></span> }
                  {total}
                </span>
                kr
              </p>
            </div>
            <div className={styles.infoDivFreeShipping}>
              <p>{total && total > 800 ? 'Gratis Frakt!' : `Du er ${800 - total},- unna fri frakt`}</p>
            </div>
          </div>
        </div>
        <div className={styles.basketBag}>
          <img src="/icons/shipping-bag.svg" alt="Cart bag" />
          <span>
            <p>{basket.totalQuantity}</p>
          </span>
        </div>
      </span>
    </button>
  );
}
