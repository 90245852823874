import React from 'react';

export default function useClickOutsideHandler({ ref, handler, excludedRef }) {
  function clickedOutsideHandler(event) {
    // ignore listening to the event -> delegate event to the original handler (in the component)
    if (excludedRef && excludedRef.current.contains(event.target)) {
      return;
    }

    if (ref.current && !ref.current.contains(event.target)) {
      handler();
    }
  }

  function escapePressedHandler(event) {
    if (event.key === 'Escape') {
      handler();
    }
  }

  React.useEffect(() => {
    document.addEventListener('mousedown', clickedOutsideHandler);
    document.addEventListener('keydown', escapePressedHandler);
    return (() => {
      document.removeEventListener('mousedown', clickedOutsideHandler);
      document.removeEventListener('keydown', escapePressedHandler);
    });
  }, [ref.current]);
}
