import { customerFragment, fragments } from './fragments';

export const RENDER_ORDER_CHECKOUT_MUTATION = `
  mutation renderOrderCheckout($input: CreateOrderCheckoutInput!) {
    createOrderCheckout(input: $input) {
      html
      klarnaOrderId
      crystallizeOrderId
    }
  }
`;

export const CREATE_MAGIC_LINK = `
  mutation createMagicLink($input: CreateLogInEmailInput!) {
    createLogInEmail(input: $input)
  } 
`;

export const DELETE_SUBSCRIPTION = `
  mutation deleteSubscription($input: DeleteProductSubscriptionInput!) {
    deleteProductSubscription(input: $input){
      id
      sku
    }
  }
`;

export const UPDATE_SUBSCRIPTION = `
  mutation updateSubscription($input: UpdateProductSubscriptionInput!) {
    updateProductSubscription(input: $input) {
      ...subscriptionItem
    }
  }
  ${fragments}
`;

export const UPDATE_CUSTOMER = `
  mutation updateCustomerInfo($patch: UpdateCustomerPatch!) {
    updateCustomer(patch: $patch) {
      ...customer
    }
  }
  ${customerFragment}
`;

export const SUBSCRIBE_TO_NEWSLETTER = `
  mutation subscribeToNews($input: SubscribeToNewsletterInput!) {
    subscribeToNewsletter(input: $input)
  }
`;
