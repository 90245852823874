import { simplyFetchFromGraph } from '../graph';
import {
  GET_ALL_SUBSCRIPTIONS, GET_PRODUCT_REVIEWS,
  GET_PRODUCT_SUBSCRIPTION, GET_SERVER_BASKET, IS_VOUCHER_VALID,
  RENDER_ORDER_CONFIRMATION,
  VALIDATE_CART_ITEM_QUERY,
  VALIDATE_CART_QUERY, VALIDATE_SUBSCRIPTION_QUERY,
} from './queries';
import {
  CREATE_MAGIC_LINK,
  DELETE_SUBSCRIPTION,
  RENDER_ORDER_CHECKOUT_MUTATION,
  SUBSCRIBE_TO_NEWSLETTER,
  UPDATE_CUSTOMER,
  UPDATE_SUBSCRIPTION,
} from './mutations';

// cartItem = {sku, path, quantity, price, priceVariantIdentifier}

export async function validateCart(cart, voucherCode) {
  try {
    const response = await simplyFetchFromGraph({
      uri: process.env.NEXT_PUBLIC_API_MIDDLEWARE_BACKEND_URL,
      query: VALIDATE_CART_QUERY,
      variables: {
        input: {
          products: cart.products,
          productSubscriptions: cart.subscriptions,
          voucherCode,
        },
      },
    });

    return response.data.cartValid.valid;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function validateProduct(product) {
  try {
    const response = await simplyFetchFromGraph({
      uri: process.env.NEXT_PUBLIC_API_MIDDLEWARE_BACKEND_URL,
      query: VALIDATE_CART_ITEM_QUERY,
      variables: {
        item: product,
      },
    });

    return response.data.productValid;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function validateSubscription(subscriptionModel) {
  try {
    const response = await simplyFetchFromGraph({
      uri: process.env.NEXT_PUBLIC_API_MIDDLEWARE_BACKEND_URL,
      query: VALIDATE_SUBSCRIPTION_QUERY,
      variables: {
        item: subscriptionModel,
      },
    });

    return response.data.productSubscriptionValid;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function renderOrderCheckout({
                                            cart, voucherCode, checkoutUrl, confirmationUrl, termsUrl,
                                          }) {
  try {
    const response = await simplyFetchFromGraph({
      uri: process.env.NEXT_PUBLIC_API_MIDDLEWARE_BACKEND_URL,
      query: RENDER_ORDER_CHECKOUT_MUTATION,
      variables: {
        input: {
          products: cart.products, // [ {sku, path, quantity, price, priceVariantIdentifier}, ... ]
          productSubscriptions: cart.subscriptions,
          voucherCode,
          urls: {
            checkout: checkoutUrl,
            confirmation: confirmationUrl,
            terms: termsUrl,
          },
        },
      },
    });

    if (response.data) {
      const { html, klarnaOrderId, crystallizeOrderId } = response.data.createOrderCheckout;
      return { html, klarnaOrderId, crystallizeOrderId };
    }

    return response;
  } catch (e) {
    console.error(e);
    throw new Error(e);
  }
}

export async function renderOrderConfirmation({ klarnaOrderId }) {
  try {
    const response = await simplyFetchFromGraph({
      uri: process.env.NEXT_PUBLIC_API_MIDDLEWARE_BACKEND_URL,
      query: RENDER_ORDER_CONFIRMATION,
      variables: {
        input: {
          klarnaOrderId,
        },
      },
    });

    if (response.data) {
      return response.data.orderConfirmation;
    }

    return response;
  } catch (e) {
    console.error(e);
    throw new Error(e);
  }
}

export async function createMagicLink(email) {
  try {
    const domain = window.location.host;
    const response = await simplyFetchFromGraph({
      uri: process.env.NEXT_PUBLIC_API_MIDDLEWARE_BACKEND_URL,
      query: CREATE_MAGIC_LINK,
      variables: {
        input: {
          emailAddress: email,
          domain,
        },
      },
    });

    return response?.data?.createLogInEmail;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function getAllSubscriptions({ amount = 20, afterCursor }) {
  try {
    const input = {
      first: amount,
      ...(afterCursor ? { after: afterCursor } : {}),
    };

    const authToken = localStorage.getItem('token');
    const response = await simplyFetchFromGraph({
      uri: process.env.NEXT_PUBLIC_API_MIDDLEWARE_BACKEND_URL,
      query: GET_ALL_SUBSCRIPTIONS,
      variables: {
        input,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response; // edges, pageInfo
  } catch (e) {
    console.error(e);
    throw new Error(e);
  }
}

export async function getSubscription(subscriptionId) {
  try {
    const authToken = localStorage.getItem('token');
    const response = await simplyFetchFromGraph({
      uri: process.env.NEXT_PUBLIC_API_MIDDLEWARE_BACKEND_URL,
      query: GET_PRODUCT_SUBSCRIPTION,
      variables: {
        id: subscriptionId,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response.data?.productSubscription;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function deleteSubscription(subscriptionId, cancellationMessage) {
  try {
    const authToken = localStorage.getItem('token');
    const response = await simplyFetchFromGraph({
      uri: process.env.NEXT_PUBLIC_API_MIDDLEWARE_BACKEND_URL,
      query: DELETE_SUBSCRIPTION,
      variables: {
        input: {
          id: subscriptionId,
          cancellationMessage: cancellationMessage || '',
        },
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response.data?.deleteProductSubscription; // id, sku, path - of deleted one
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function updateSubscription({ subscriptionId, nextOrderAt, deliveryAddress }) {
  try {
    const authToken = localStorage.getItem('token');
    const input = {
      id: subscriptionId,
      patch: {
        ...(nextOrderAt ? { nextOrderAt } : {}),
        ...(deliveryAddress ? { deliveryAddress } : {}),
      },
    };

    const response = await simplyFetchFromGraph({
      uri: process.env.NEXT_PUBLIC_API_MIDDLEWARE_BACKEND_URL,
      query: UPDATE_SUBSCRIPTION,
      variables: {
        input,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response; // updated subscription
  } catch (e) {
    console.error(e);
    throw new Error(e);
  }
}

export async function isVoucherCodeValid({ voucherCode }) {
  try {
    const response = await simplyFetchFromGraph({
      uri: process.env.NEXT_PUBLIC_API_MIDDLEWARE_BACKEND_URL,
      query: IS_VOUCHER_VALID,
      variables: {
        input: voucherCode,
      },
    });

    return response.data?.voucherCodeValid;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function getServerBasket({ products, productSubscriptions, voucherCode }) {
  try {
    const response = await simplyFetchFromGraph({
      uri: process.env.NEXT_PUBLIC_API_MIDDLEWARE_BACKEND_URL,
      query: GET_SERVER_BASKET,
      variables: {
        input: {
          products,
          productSubscriptions,
          voucherCode,
        },
      },
    });

    if (response.errors) {
      throw Error(response.errors);
    }

    return response.data?.basket;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function updateCustomerInfo({ customer }) {
  const authToken = localStorage.getItem('token');

  const input = {
    ...customer,
  };

  try {
    const response = await simplyFetchFromGraph({
      uri: process.env.NEXT_PUBLIC_API_MIDDLEWARE_BACKEND_URL,
      query: UPDATE_CUSTOMER,
      variables: {
        patch: input,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response.data?.updateCustomer;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function subscribeToNews(email) {
  try {
    const input = {
      emailAddress: email,
    };

    const response = await simplyFetchFromGraph({
      uri: process.env.NEXT_PUBLIC_API_MIDDLEWARE_BACKEND_URL,
      query: SUBSCRIBE_TO_NEWSLETTER,
      variables: {
        input,
      },
    });

    return response;
  } catch (e) {
    console.error(e);
    throw new Error(e);
  }
}

export async function getProductReviews({ productId, page, count }) {
  try {
    const input = {
      yotpoId: productId,
      ...({ page } || {}),
      ...({ count } || {}),
    };

    const response = await simplyFetchFromGraph({
      uri: process.env.NEXT_PUBLIC_API_MIDDLEWARE_BACKEND_URL, // process.env.NEXT_PUBLIC_API_MIDDLEWARE_BACKEND_URL,
      query: GET_PRODUCT_REVIEWS,
      variables: {
        input,
      },
    });

    return response;
  } catch (e) {
    console.error(e);
    throw new Error(e);
  }
}
