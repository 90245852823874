import React from 'react';
import styles from './styles.module.scss';
import Spinner from '../../../../atoms/spinner';
import { isVoucherCodeValid } from '../../../../../lib/backend';
import { useBasket } from '../../../basket';

export default function CouponInput() {
  const [couponIsShown, setCouponIsShown] = React.useState(false);
  const [couponIsLoading, setCouponIsLoading] = React.useState(false);
  const [couponError, setCouponError] = React.useState(false);
  const couponRef = React.useRef();
  const basket = useBasket();

  async function applyCoupon(e) {
    e.preventDefault();

    const voucherCode = couponRef.current.value;
    if (voucherCode === '') {
      return;
    }

    setCouponIsLoading(true);

    const isCouponValid = await isVoucherCodeValid({ voucherCode });

    if (isCouponValid) {
      basket.actions.addVoucherCode(voucherCode);
    } else {
      setCouponError(true);
    }

    setCouponIsLoading(false);
  }

  if (basket.basketModel.voucherCode) {
    return (
      <>
        <div style={{ color: '#51C299', marginTop: '9px' }}>
          ✓ Voucher activated!
          <span
            role="button"
            tabIndex={0}
            onClick={() => basket.actions.removeVoucherCode()}
            style={{ fontSize: '0.7em', marginLeft: '9px', color: 'black' }}
          >
            (cancel)
          </span>
        </div>
      </>
    );
  }

  return (
    <>
      <div
        className={styles.couponLink}
        onClick={() => setCouponIsShown((_shown) => !_shown)}
      >
        {couponIsShown ? 'Cancel' : 'Klikk her for å legge til en kupongkode'}
      </div>
      {couponIsShown && (
        <div className={styles.couponInputContainer}>
          <form onSubmit={applyCoupon}>
            <input role="presentation" style={{ marginBottom: '6px' }} placeholder="your coupon code" ref={couponRef} />
            <button type="submit" className={styles.couponButton} onClick={applyCoupon}>
              {couponIsLoading ? <Spinner size={12} /> : <div>➞</div>}
            </button>
          </form>
          { couponError && <div style={{ color: '#eb1e4e', fontSize: '0.8em' }}>Invalid coupon code</div>}
        </div>
      )}
    </>
  );
}
