import styled from 'styled-components';
import { Image as CrystallizeImage } from '@crystallize/react-image';

export const Row = styled.div`
  width: 100%;
`;

export const ItemDelete = styled.button`
  position: absolute;
  top: 2px;
  right: 2px;
  font-size: 0;
  background-color: transparent;
  border: none;
  width: 20px;

  &::before {
    content: '+';
    display: block;
    transform: rotate(45deg);
    font-size: 21px;
    opacity: 0.8;
    font-weight: 200;
  }
`;

export const Item = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 0.5fr;
  align-items: center;
  padding: 21px;
  border: 1px solid #E0E0E0;
  position: relative;
  column-gap: 10px;
  background-color: ${(props) => (props.isSubscription ? '#EEFFFE' : 'white')};
  overflow: hidden;
`;

export const ItemInfo = styled.span`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;

export const ItemInfoText = styled.div``;

const imageWidth = '50px';

export const ItemImage = styled(CrystallizeImage).attrs(() => ({
  sizes: imageWidth,
}))`
  width: ${imageWidth};
  position: relative;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const ImageImageEmpty = styled.div`
  display: inline-block;
  width: 50px;
`;

export const ItemName = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  text-align: left;
  font-weight: bold;
`;

export const ItemQuantityChanger = styled.span`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  button {
    margin: 6px 0;
  }
`;

export const ItemQuantity = styled.span`
  display: inline-block;
  margin: 0 3px;
  min-width: 23px;
  text-align: center;
`;

export const PriceWrapper = styled.div`
  font-size: 16px;
  padding: 5px 10px 0 0;
  margin: 4px 0;
  color: var(--color-text-main);
`;
export const PriceWrap = styled.div`
  display: flex;
`;

export const Price = styled.div`
  ${(p) => p.isDiscounted && 'text-decoration: line-through'};
  text-align: left;
  font-size: 11px;
  font-weight: 300;
`;

export const SubInfoOuter = styled.div`
  font-size: 0.8rem;
`;

export const SubInfoLine = styled.div`
  margin-top: 5px;
`;
