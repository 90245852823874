import styled from 'styled-components';

export const Outer = styled.div`
  flex-grow: 1;
`;

export const Rows = styled.div`
  position: relative;
`;

export const SpinnerWrap = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 13px;
  padding: 9px 0 9px 0;
  
  span:first-child {
    text-align: left;
  }
  
  span:last-child {
    text-align: right;
  }

  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;

export const RowValue = styled.span`
  font-weight: 600;
  text-align: right;
  opacity: ${(p) => (p.hide ? '0' : '1')};
`;

export const StrikeThrough = styled.div`
  text-decoration: line-through;
  font-size: 0.8em;
  position: absolute;
  right: 0;
  transform: translateY(-15px);
`;
