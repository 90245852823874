import React from 'react';

export default function useIsScrolledToTheTop() {
  const [isOnTop, setIsOnTop] = React.useState(false);

  function handler() {
    if (window.pageYOffset === 0 || window.pageYOffset < 0) {
      setIsOnTop(true);
    } else {
      setIsOnTop(false);
    }
  }

  React.useEffect(() => {
    document.addEventListener('scroll', handler);
    return () => {
      document.removeEventListener('scroll', handler);
    };
  }, []);

  return isOnTop;
}
