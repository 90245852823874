import React from 'react';

import { useBasket } from '../index';
import TinyBasketItem from './item';

import {
  Outer, Items, ItemOuter, BasketIsEmpty,
} from './styles';

export default function TinyBasket() {
  const basket = useBasket();

  if (basket.status === 'not-hydrated') {
    return null;
  }

  if (!basket.cart.length) {
    return (
      <Outer>
        <BasketIsEmpty>Tømme</BasketIsEmpty>
      </Outer>
    );
  }

  function render() {
    return (
      basket.cart.map((item) => (
        <ItemOuter key={item.sku}>
          <TinyBasketItem item={item} />
        </ItemOuter>
      ))
    );
  }

  return (
    <Outer>
      <Items>
        {render()}
      </Items>
    </Outer>
  );
}
