export default function SEO({
 title, description, imageUrl, canonicalLink,
}) {
  return (
    <>
      <link rel="canonical" href={canonicalLink} />
      <meta name="title" content={`${title} | MR`} />
      <meta name="description" content={description} />

      {/* Facebook & LinkedIn */}
      <meta name="og:title" content={`${title} | MR`} />
      <meta name="og:description" content={description} />
      <meta name="og:url" content={canonicalLink} />
      <meta name="og:image" content={imageUrl} />
      <meta name="og:image:secure_url" content={imageUrl} />

      {/* Twitter */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@MyRevolution" />
      <meta name="twitter:title" content={`${title} | MR`} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
    </>
  );
}
