import React from 'react';
import Link from 'next/link';
import styles from './Navbar.module.scss';
import Mailer from './mailer/Mailer';
import Basket from './basket';
import Burger from '../../../atoms/buttons/burger/Burger';

export default function Navbar({
 isMenuToggled, toggleMenu, toggleBasket, mainMenuBtnRef, basketMenuBtnRef,
}) {
  const [navbarTransparent, setNavbarTransparent] = React.useState(true);

  function scrollHandler() {
    if (window.pageYOffset > 50) {
      setNavbarTransparent(false);
    } else {
      setNavbarTransparent(true);
    }
  }

  React.useEffect(() => {
    document.addEventListener('scroll', scrollHandler);
    return (() => {
      document.removeEventListener('scroll', scrollHandler);
    });
  }, []);

  return (
    <div className={`${styles.container} ${navbarTransparent ? styles.transparent : null}`}>
      <div className={styles.innerContainer}>
        <div className={styles.leftSection}>
          <div className="desktopOnly">
            <Burger
              isToggled={isMenuToggled}
              toggleMenu={toggleMenu}
              btnRef={mainMenuBtnRef}
            />
          </div>
          <Link prefetch={false} href="/">
            <a href="/">
              <img src="/logo/logo-text.png" alt="My Revolution" className={styles.logo} />
            </a>
          </Link>
        </div>

        <div className={styles.rightSection}>
          <Basket toggleBasket={toggleBasket} basketMenuBtnRef={basketMenuBtnRef} />
          <Mailer />
        </div>
      </div>
    </div>
  );
}
