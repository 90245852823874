import React from 'react';
import TinyBasket from 'components/templates/basket/tiny-basket';
import Totals from 'components/templates/basket/totals';
import { useBasket } from 'components/templates/basket';
import { useRouter } from 'next/router';
import styles from './Aside.module.scss';
import { validateCart } from '../../../../lib/backend';
import Spinner from '../../../atoms/spinner';
import CouponInput from './coupon-input/CouponInput';
import SuggestedProduct from './suggested-product/SuggestedProduct';

export default function Aside({ toggleBasket, selfReference }) {
  const [going, setGoing] = React.useState(false);

  const basket = useBasket();
  const router = useRouter();

  const onCheckoutClick = async (evt) => {
    setGoing(true);

    if (!basket.cart.length) {
      evt.preventDefault();
      return;
    }

    const isValid = await validateCart(basket.basketModel.cart, basket.basketModel.voucherCode);

    if (isValid) {
      // redirect without page reload
      await router.push('/checkout', null, { shallow: true });
    } else {
      alert('Your cart is invalid. Try clearing the cart before continuing.');
    }
    setGoing(false);
  };

  if (basket.status === 'not-hydrated') {
    return 'Loading ...';
  }

  return (
    <div className={styles.outer} ref={selfReference}>
      <div className={styles.header}>
        <div>
          <h2>Handlekurv</h2>
          <CouponInput />
        </div>
        <button type="button" className={styles.toggleBasket} onClick={toggleBasket}>x</button>
      </div>

      <div className={styles.content}>
        <TinyBasket />
        <SuggestedProduct />
      </div>

      <div className={styles.footer}>
        <Totals />
        {/* { basket.cart.length ? <div onClick={basket.actions.empty} className={styles.trashbin}><img src="/icons/trash.svg" style={{width: '15px'}} /></div> : null } */}
        <hr />
        <button
          type="button"
          onClick={toggleBasket}
          className={styles.continueShopping}
        >
          Fortsett å handle
        </button>
        <button
          type="submit"
          className={`${styles.checkoutBtn} btn btnGreen`}
          disabled={!basket.cart.length}
          onClick={onCheckoutClick}
        >
          {!going ? 'Gå til betaling' : <Spinner />}
        </button>
      </div>
    </div>
  );
}
