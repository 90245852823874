import React from 'react';
import { useBasket } from '../index';
import {
 StrikeThrough,
  Outer, Rows, Row, RowValue, SpinnerWrap,
} from './styles';

import Spinner from '../../../atoms/spinner';

export default function Totals(props) {
  const basket = useBasket();
  const {
   cart, total: totalClient, serverBasket, status,
  } = basket;

  if (cart.length === 0) {
    return null;
  }

  const printCurrencyAmount = React.useCallback(() => {
    if (serverBasket && serverBasket.totalPrice) {
      const totalAmountServer = serverBasket.totalPrice.gross;
      if (totalAmountServer < totalClient) {
        return (
          <>
            <StrikeThrough>
              {totalClient}
              , -NOK
            </StrikeThrough>
            <Total total={totalAmountServer} />
          </>
        );
      }
      return <Total total={totalAmountServer} />;
    }
    return <Total total={totalClient} />;
  }, [serverBasket, status]);

  const Total = ({ total }) => (
    <>
      {basket.status !== 'ready' && <span style={{ margin: '3px' }}><Spinner size={15} /></span>}
      {' '}
      {Math.round(total)}
      ,- NOK
    </>
  );

  const isLoading = false;

  return (
    <Outer {...props}>
      <Rows>
        {isLoading && (
          <SpinnerWrap>
            <p>Loading...</p>
          </SpinnerWrap>
        )}
        <hr />
        <Row>
          <span>
            Total to pay
            :
          </span>
          <RowValue hide={isLoading}>
            {printCurrencyAmount()}
          </RowValue>
        </Row>
      </Rows>
    </Outer>
  );
}
