import React from 'react';
import isEmail from 'util/helpers';
import Modal from 'components/templates/modals/Modal';
import styles from './Mailer.module.scss';
import { subscribeToNews } from '../../../../../lib/backend';
import Spinner from '../../../../atoms/spinner';

export default function Mailer() {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  function becomeMemberDialog() {
    setIsModalOpen(true);
  }

  function handleCloseDialog() {
    setIsModalOpen(false);
  }

  return (
    <div className={styles.container}>
      <a onClick={() => setIsOpen(!isOpen)} /* eslint-disable-line */>
        Bli med i kundeklubben
        <br />
        <span>Trykk her for å vite mer</span>
      </a>
      <div
        onClick={becomeMemberDialog}
        onKeyPress={becomeMemberDialog}
        role="button"
        tabIndex={0}
      >
        <img
          src="/icons/kundeklubb.svg"
          alt="Open cart"
        />
      </div>
      {isModalOpen && (
        <Modal handleClose={handleCloseDialog} render={() => <BecomeMemberDialog />} />
      )}

      <div className={`${styles.becomeMemberDropdown} ${isOpen && styles.becomeMemberDropdownActive}`}>
        <BecomeMemberDialog />
      </div>
    </div>
  );
}

function BecomeMemberDialog() {
  const [state, setState] = React.useState({ message: null, success: false, loading: false });
  const inputRef = React.useRef('#emailInput');

  // const onChange = ({target : {value}}) => setState({...state, email: value});
  const onSubmit = async (e) => {
    e.preventDefault();
    const email = inputRef.current.value;
    if (isEmail(email) === true) {
      setState({ ...state, message: '', loading: true });

      const response = await subscribeToNews(email);

      if (response && !response.errors) {
        setState({
 email, message: 'Du abonnerer!', success: true, loading: false,
});
      } else {
        setState({
 email: null, message: response.errors[0].message, success: true, loading: false,
});
      }
    } else {
      setState({ ...state, message: 'Ikke et gyldig e-postformat' });
    }
  };

  return (
    <div className="dialog-wrapper">
      <form onSubmit={onSubmit}>
        {!state.success && (
          <div className={styles.dialogContent}>
            <input
              ref={inputRef}
              id="emailInput"
              name="Email"
              type="email"
              placeholder="Email"
            />
          </div>
        )}
        {state.message}
        <div className={styles.dialogFooter}>
          <ul className={styles.perksList}>
            <li>Få først vite om kampanjer og nyheter</li>
            <li>Lær om kropp og helse av Nordens ledende autoriteter</li>
            <li>Tilgang til eksklusive arrangementer</li>
          </ul>
          {!state.success && (
            <button type="submit" className="btn">
              { state.loading ? <Spinner color="white" /> : 'Få tilgang' }
            </button>
          )}
        </div>
      </form>
    </div>
  );
}
