import React, {
  useEffect, useReducer, useRef, useMemo,
} from 'react';
import { retrieveFromCache, persistToCache } from './cache';
import reducer, { initialState } from './reducer';
import { getChannel } from './shared-channel';
import { getServerBasket } from '../../../lib/backend';

const BasketContext = React.createContext(null);

export const useBasket = () => React.useContext(BasketContext);

export function BasketProvider({ locale, children }) {
  const [
    {
      status,
      clientBasket,
      serverBasket,
      totalQuantity,
      changeTriggeredByOtherTab,
      attentionCart,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  const [cart, setCart] = React.useState(null);
  const [total, setTotal] = React.useState(0);

  const fetchTimeout = useRef(null);
  const sharedChannelRef = useRef(getChannel());

  function dispatchCartItemAction(action) {
    return (data) => dispatch({ action, ...data });
  }

  useEffect(() => {
    // Retrieve cached basket
    (async function init() {
      const cache = await retrieveFromCache();
      dispatch({ action: 'hydrate', ...cache });
    }());

    // Listen for channel updates
    if (sharedChannelRef.current) {
      sharedChannelRef.current.onmessage = (event) => {
        dispatch({ action: 'channel-update', ...JSON.parse(event.data) });
      };
    }
  }, []);

  // Persist the basket on the client
  useEffect(() => {
    if (status !== 'not-hydrated') {
      persistToCache({
        ...clientBasket,
        cart: clientBasket.cart.map((cartItem) => cartItem),
      });
    }
  }, [status, clientBasket]);

  /**
   * Broadcast this change to anyone listening to the channel
   * This is typically other tabs opened for this site, thus
   * enabling a synced cart across all open tabs
   */
  useEffect(() => {
    if (status === 'ready') {
      if (!changeTriggeredByOtherTab) {
        sharedChannelRef.current?.postMessage(
          JSON.stringify({
            clientBasket,
            serverBasket,
          }),
        );
      }
    }
  }, [status, clientBasket, serverBasket, changeTriggeredByOtherTab]);

  React.useEffect(() => {
    const newCart = clientBasket.cart.map((cartItem) => cartItem);
    setCart(newCart);

    const newTotal = newCart.reduce((sum, currentItem) => (
      sum + currentItem.price * (currentItem.isPackaged ? 1 : currentItem.quantity)
    ), 0);

    setTotal(newTotal);
  }, [clientBasket]);

  /**
   * Define the basketModel object.
   * Used for the checkout.
   */
  const basketModel = useMemo(
    () => ({
      locale: { locale, appLanguage: 'Norwegian' },
      cart: segregateBasket(clientBasket.cart), // separate subscriptions and products
      voucherCode: clientBasket.voucherCode,
      crystallizeOrderId: clientBasket.crystallizeOrderId,
      klarnaOrderId: clientBasket.klarnaOrderId,
    }),
    [locale, clientBasket],
  );

  function segregateBasket(_cart) {
    // transform 1x list of cartItems into 2x separate lists: products[], subscriptions[]
    if (!_cart && _cart.length === 0) {
      return {
        products: [],
        subscriptions: [],
      };
    }
    const basket = _cart.reduce((acc, curr) => {
      // check if added product is pakketilbud
      // if (curr.attributes.find(a => a.))

      // transfer quantity from basket item ui -> basket item model
      const basketItemModel = { ...curr.model };
      basketItemModel.quantity = curr.quantity;

      if (curr.subscription) {
        return {
          products: [...acc.products],
          subscriptions: [...acc.subscriptions, basketItemModel],
        };
      }

      return {
        products: [...acc.products, basketItemModel],
        subscriptions: [...acc.subscriptions],
      };
    }, {
      products: [],
      subscriptions: [],
    });

    return basket;
  }

  // SERVER BASKET UPDATES
  useEffect(() => {
    async function getServerSideBasket() {
      try {
        const serverSideBasket = await getServerBasket({
          products: basketModel.cart.products,
          productSubscriptions: basketModel.cart.subscriptions,
          voucherCode: basketModel.voucherCode,
        });

        if (!serverSideBasket) {
          dispatchCartItemAction('server-update-failed');
          return;
        }

        dispatch({
          action: 'set-server-basket',
          serverBasket: serverSideBasket,
        });
      } catch (e) {
        console.error(e);
      }
    }

    if (status === 'server-basket-is-stale') {
      clearTimeout(fetchTimeout.current);
      fetchTimeout.current = setTimeout(getServerSideBasket, 300);
    }

    return () => {
      clearTimeout(fetchTimeout.current);
    };
  }, [basketModel, status]);

  return (
    <BasketContext.Provider
      value={{
        status,
        serverBasket,
        clientBasket,
        basketModel,
        cart,
        total,
        totalQuantity,
        attentionCart,
        actions: {
          addVoucherCode: (voucherCode) => dispatch({ action: 'add-voucher', voucherCode }),
          removeVoucherCode: () => dispatch({ action: 'remove-voucher' }),
          empty: () => dispatch({ action: 'empty' }),
          addItem: dispatchCartItemAction('add-item'),
          removeItem: dispatchCartItemAction('remove-item'),
          incrementItem: dispatchCartItemAction('increment-item'),
          decrementItem: dispatchCartItemAction('decrement-item'),
          drawAttention: (sku) => dispatch({ action: 'draw-attention', sku }),
          setCrystallizeOrderId: (crystallizeOrderId) => dispatch({
            action: 'set-crystallize-order-id',
            crystallizeOrderId,
          }),
          setKlarnaOrderId: (klarnaOrderId) => dispatch({ action: 'set-klarna-order-id', klarnaOrderId }),
        },
      }}
    >
      {children}
    </BasketContext.Provider>
  );
}
