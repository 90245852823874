import styles from './styles.module.scss';

export default function Burger({ toggleMenu, isToggled, btnRef }) {
  return (
    <div
      className={`${styles.burger} ${isToggled ? styles.burgerActive : null}`}
      onKeyPress={toggleMenu}
      onClick={toggleMenu}
      role="button"
      tabIndex={0}
      ref={btnRef}
    >
      <span />
      <span />
      <span />
    </div>
  );
}
