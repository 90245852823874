/* eslint-disable react/jsx-no-bind */
import React from 'react';
import Head from 'next/head';
import Navbar from 'components/organisms/navigation/navbar/Navbar';
import Sidemenu from 'components/organisms/navigation/sidemenu/Sidemenu';
import Footer from 'components/organisms/footer/Footer';
import Swirl from 'components/molecules/swirl';
import Aside from './aside';
import SEO from '../seo/Seo';
import { useBasket } from '../basket';
import useClickOutsideHandler from '../../../hooks/useClickOutsideHandler';
import BurgerMobile from '../../atoms/buttons/burger-mobile/BurgerMobile';

export default function Layout({
  children,
  title = 'Din kosttilskuddsleverandør',
  footerDocs,
  description = 'Nettbutikk for protein, kollagen, kosttilskudd og annet tilbehør.',
  imageUrl = 'https://media-dev.crystallize.digital/myrevolution/21/9/23/2/@500/screenshot-2021-09-23-at-8-31-36-pm.png',
  canonical,
  stripeColor,
}) {
  const canonicalUrl = canonical && `https://myrevolution.no${canonical}`;

  const isInitialMount = React.useRef(false);
  const basket = useBasket();
  const { attentionCart } = basket;

  const [menuActive, setMenuActive] = React.useState(false);
  const [basketActive, setBasketActive] = React.useState(false);
  const [menuMobileActive, setMenuMobileActive] = React.useState(false);

  const mainMenuRef = React.useRef(null);
  const mainMenuBtnRef = React.useRef(null);

  const basketMenuRef = React.useRef(null);
  const basketMenuBtnRef = React.useRef(null);

  useClickOutsideHandler({ ref: mainMenuRef, handler: () => setMenuActive(false), excludedRef: mainMenuBtnRef });
  useClickOutsideHandler({ ref: basketMenuRef, handler: () => setBasketActive(false), excludedRef: basketMenuBtnRef });

  // open cart whenever product is added (but skip on component mount);
  React.useEffect(() => {
    if (isInitialMount.current) {
      setBasketActive((_s) => !_s && true);
    } else {
      isInitialMount.current = true;
    }
  }, [attentionCart]);

  // REMOVE SCROLL WHEN MENU's ACTIVATED
  React.useEffect(() => {
    if (menuActive || basketActive || menuMobileActive) {
      document.body.style.overflow = 'hidden';
    }

    if (!menuActive && !basketActive && !menuMobileActive) {
      document.body.style.overflow = 'initial';
    }
  }, [menuActive, basketActive, menuMobileActive]);

  function toggleMenu() {
    setMenuActive((_s) => !_s);
  }

  function toggleBasket() {
    setBasketActive((_s) => !_s);
  }

  function toggleMenuMobile() {
    setMenuMobileActive((_s) => !_s);
  }

  return (
    <>
      <Head>
        <title key="title">{title}</title>
        <SEO
          title={title}
          description={description}
          canonicalLink={canonicalUrl}
          imageUrl={imageUrl}
        />
        <meta name="viewport" content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Head>

      <header className={`${menuActive ? 'sidenavActive' : '' } ${basketActive ? 'basketActive' : ''}`}>
        <Navbar
          isMenuToggled={menuActive}
          toggleMenu={toggleMenu}
          toggleBasket={toggleBasket}
          mainMenuBtnRef={mainMenuBtnRef}
          basketMenuBtnRef={basketMenuBtnRef}
        />
      </header>

      <nav className={`mainNav ${menuActive ? 'sidenavActive' : ''} ${basketActive ? 'basketActive' : ''}`}>
        <Sidemenu selfReference={mainMenuRef} />
      </nav>

      <nav className={`basketNav ${basketActive ? 'basketActive' : ''}`}>
        <Aside toggleBasket={toggleBasket} selfReference={basketMenuRef} />
      </nav>

      <main className={`${menuActive ? 'sidenavActive' : ''} ${basketActive && 'basketActive'}`}>
        {children}
      </main>

      <nav
        className="mobileNav"
        style={menuMobileActive ? { transform: 'translateY(0vh)' } : { transform: 'translateY(100%)' }}
      >
        <Sidemenu />
      </nav>

      <BurgerMobile
        basketToggled={basketActive}
        isToggled={menuMobileActive}
        toggleMenu={toggleMenuMobile}
      />

      {
        stripeColor
        && (
        <Swirl
          color={stripeColor}
          style={
              menuActive ? { transform: 'translateX(260px)' } : basketActive ? { transform: 'translateX(-360px)' } : { transform: 'translateX(0px)' }
            }
        />
)
      }

      <div id="modal" />

      <footer className={`${menuActive ? 'sidenavActive' : ''} ${basketActive ? 'basketActive' : ''}`}>
        <Footer footerDocs={footerDocs} />
      </footer>
    </>
  );
}
