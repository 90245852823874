import Link from 'next/link';
import styles from './Sidemenu.module.scss';

export default function Sidemenu({ selfReference }) {
  return (
    <div className={styles.container} ref={selfReference}>
      <div className={styles.menuTitle}>MENU</div>
      <div className={styles.menu}>
        <Link href="/proteinpulver">Proteinpulver</Link>
        <Link href="/kollagen">Kollagen</Link>
        <Link href="/protein-bar">Protein Bar</Link>
        <Link href="/sunt-og-godt">Sunt & Godt</Link>
        <Link href="/kosttilskudd">Kosttilskudd</Link>
        <Link href="/naturlig-livsstil">Naturlig Livsstil</Link>
        <Link href="/accessories">Accessories</Link>
        <hr />
        <Link prefetch={false} href="/inspirasjon">Inspirasjon</Link>
        <Link prefetch={false} href="/min-side">Min Side</Link>
        <hr />
      </div>
    </div>
  );
}
