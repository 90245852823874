import React from 'react';

import { useBasket } from 'components/templates/basket';
import AttributeList from 'components/templates/basket/attribute-list';

import {
  Item,
  Row,
  ItemInfo,
  PriceWrapper,
  ImageImageEmpty,
  ItemImage,
  ItemName,
  ItemQuantityChanger,
  ItemQuantity,
  ItemDelete,
  PriceWrap,
  Price,
} from './styles';

export default function TinyBasketItem({ item }) {
  const { actions } = useBasket();

  const { attributes, images, subscription: isSubscription } = item;

  function increment() {
    actions.incrementItem(item);
  }

  function decrement() {
    actions.decrementItem(item);
  }

  function remove() {
    actions.removeItem(item);
  }

  if (item.sku.startsWith('--voucher--')) {
    return (
      <Item isSubscription={item.subscription}>
        <ImageImageEmpty>
          {isSubscription && (
          <div>
            subscription
            <br />
            (
            {item.subscriptionPlan}
            ):
          </div>
)}
          {' '}
          {item.name}
        </ImageImageEmpty>
        <PriceWrapper>
          <PriceWrap>
            <Price>
              {`${Math.round(item.price * item.quantity)} NOK`}
            </Price>
          </PriceWrap>
        </PriceWrapper>
        <ItemDelete onClick={actions.removeVoucherCode}>
          Remove item
        </ItemDelete>
      </Item>
    );
  }

  return (
    <Item isSubscription={isSubscription}>
      <ItemImage {...images?.[0]} />
      <ItemInfo>
        <Row>
          <ItemName>
            {isSubscription && (
            <div>
              subscription
              <br />
              (
              {item.subscriptionPlan}
              ):
            </div>
)}
            {' '}
            {item.name}
          </ItemName>
          <hr />
          {attributes?.length > 0 && <AttributeList attributes={attributes} />}
        </Row>

        <PriceWrapper>
          <PriceWrap>
            <Price>
              {!isSubscription
                ? `${Math.round(item.price * item.quantity)},- NOK`
                : (
                  <>
                    <div>
                      First delivery:
                      {' '}
                      {item.initialPeriod.price * item.quantity}
                      ,- NOK
                    </div>
                    <div>
                      Renewal delivery:
                      {' '}
                      {item.recurringPeriod.price * item.quantity}
                      ,- NOK
                    </div>
                  </>
)}
            </Price>
          </PriceWrap>
        </PriceWrapper>
      </ItemInfo>

      <ItemQuantityChanger>
        <button
          onClick={decrement}
          type="button"
          disabled={item.quantity === 1}
        >
          -
        </button>
        <ItemQuantity>{item.quantity}</ItemQuantity>
        <button onClick={increment} type="button">
          +
        </button>
      </ItemQuantityChanger>

      <ItemDelete onClick={remove}>Remove item</ItemDelete>
    </Item>
  );
}
