import React from 'react';
import styles from './styles.mobile.module.scss';
import Burger from '../burger/Burger';
import useIsScrollingDown from '../../../../hooks/useIsScrollingDown';
import useIsScrolledToTheTop from '../../../../hooks/useIsScrolledOnTop';

export default function BurgerMobile({ isToggled, toggleMenu, basketToggled }) {
  const isUserScrollingDown = useIsScrollingDown();
  const isUserScrolledToTheTop = useIsScrolledToTheTop();

  const [menuHidden, setMenuHidden] = React.useState(false);

  React.useEffect(() => {
    if (basketToggled) {
      setMenuHidden(true);
      return;
    }
      setMenuHidden(false);

    if (isUserScrolledToTheTop) {
      setMenuHidden(false);
    } else {
      setMenuHidden(isUserScrollingDown);
    }
  }, [isUserScrollingDown, isUserScrolledToTheTop, basketToggled]);

  return (
    <div
      className={styles.burgerWrapperMobile}
      style={menuHidden ? { transform: 'translateY(120px)' } : { transform: 'translateY(0px)' }}
    >
      <div>
        <Burger
          isToggled={isToggled}
          toggleMenu={toggleMenu}
        />
      </div>
    </div>
  );
}
