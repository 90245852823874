import styles from './swirl.module.scss';

export default function Swirl({ color, style }) {
  return (
    <div
      style={{ ...style }}
      className={styles.swirl}
    >
      <svg width="506px" height="759px" viewBox="0 0 506 759" version="1.1">
        <defs>
          <linearGradient
            x1="50%"
            y1="97.0982143%"
            x2="50%"
            y2="0%"
            id="linearGradient-1"
          >
            <stop stopColor="#FFFFFF" offset="0%" />
            <stop stopColor={color} offset="100%" />
          </linearGradient>
        </defs>
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Desktop-HD"
            transform="translate(-332.000000, 0.000000)"
            fill="url(#linearGradient-1)"
          >
            <path
              d="M503.131829,761 L472.249611,761 L332,761 C332.23006,636.036528 332.34509,542.183631 332.34509,479.441308 C332.34509,450.838664 332.34509,407.67411 332.34509,349.947648 C335.768157,229.469833 375.959332,117.960962 442.135617,26.1394709 C448.562599,17.246592 455.228452,8.53268932 462.130923,1.8189894e-12 L838,0 C656.298031,17.4814982 512.545004,165.436631 503.150162,347.976673 L503.131829,761 Z"
              id="swirl"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}
