export default function isEmail(email) {
  return (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email);
}

/* eslint-disable no-param-reassign */
export function parseAndMountHtmlKlarnaSnippet(snippet, snippetContainerRef) {
  snippetContainerRef.current.innerHTML = snippet;
  const scriptsTags = snippetContainerRef.current.getElementsByTagName('script');
  // This is necessary otherwise the scripts tags are not going to be evaluated
  /* eslint-disable-next-line no-alert */
  for (let i = 0; i < scriptsTags.length; i++) {
    const { parentNode } = scriptsTags[i];
    const newScriptTag = document.createElement('script');
    newScriptTag.type = 'text/javascript';
    newScriptTag.text = scriptsTags[i].text;
    parentNode.removeChild(scriptsTags[i]);
    parentNode.appendChild(newScriptTag);
  }
}

export function parseDate(date) {
  return new Date(date);
}

export function getLimitedDecimal(number) {
  return +(`${Math.round(`${parseFloat(number) }e+2`) }e-2`);
}

export function getInputBasedFormatDate(date) {
  const parsedDate = new Date(date);
  // e.g. -> 2013-01-08
  return parsedDate.toISOString().substr(0, 10);
}
