import { cartItemFragment, fragments } from './fragments';

export const VALIDATE_CART_ITEM_QUERY = `
  query validateCartItem($item: ProductInput!) {
    productValid(product: $item){
      valid
      error{
        code
        message
        product{
          sku
          quantity
          price
        }
      }
    }
  }
`;

export const VALIDATE_SUBSCRIPTION_QUERY = `
  query validateSubscriptionItem($item: ProductSubscriptionInput!) {
    productSubscriptionValid(subscription: $item){
      valid
      error{
        code
        message
        productSubscription{
          sku
        }
      }
    }
  }
`;

export const VALIDATE_CART_QUERY = `
  query validateCart($input: CartValidInput!) {
    cartValid(input: $input) {
      valid
      productErrors{
        code
        message
        product {
          sku
          price
          quantity
        }
      }
      productSubscriptionErrors{
        code
        message
        productSubscription{
          sku
          quantity
        }
      }
      voucherCodeError
    }
  }
`;

export const RENDER_ORDER_CONFIRMATION = `
  query renderOrderConfirmation($input: OrderConfirmationInput!) {
    orderConfirmation(input: $input) {
      html
      crystallizeOrderId
      email
      phone
    }
  }
`;

export const GET_PRODUCT_SUBSCRIPTION = `
  query getProductSubscription($id: ID!) {
    productSubscription(id: $id){
      ...subscriptionItem
    }
  }  
  ${fragments}
`;

export const GET_ALL_SUBSCRIPTIONS = `
  query getProductSubscriptions($input: ConnectionInput!) {
    productSubscriptionsConnection(input: $input) {
      edges{
        node{
          ...subscriptionItem
        }
      }
      pageInfo{
        hasNextPage
        endCursor
      }
    }
  }
  ${fragments}
`;

export const IS_VOUCHER_VALID = `
  query isCouponValid($input: String!) {
    voucherCodeValid(code: $input)
  }
`;

export const GET_SERVER_BASKET = `
  query getServerBasket($input: CartValidInput!){
    basket(input: $input) {
      cart{
        ...cartItem
      }
      totalPrice{
        gross
        net
      }
    }
  }
  ${cartItemFragment}
`;

export const GET_PRODUCT_REVIEWS = `
  query getProductReviews($input: ProductReviewsInput!){
    productReviews(input: $input)
  }
`;
