import React from 'react';

export default function useEscapeModal({ ref }) {
  const [modalEscaped, setModalEscaped] = React.useState(false);

  // clicked outside of modal
  function clickHandler(event) {
    if (ref.current && event.target === ref.current) {
      setModalEscaped(true);
    }
  }

  // esc key
  function keydownHandler(event) {
    if (event.key === 'Escape') {
      setModalEscaped(true);
    }
  }

  React.useEffect(() => {
    document.addEventListener('mousedown', clickHandler, true);
    document.addEventListener('keydown', keydownHandler, true);
    return () => {
      document.removeEventListener('mousedown', clickHandler, true);
      document.removeEventListener('keydown', keydownHandler, true);
    };
  }, []);

  return modalEscaped;
}
